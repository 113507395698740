import React from 'react';
import PropTypes from 'prop-types';
import {makeStyles} from '@material-ui/core/styles';
import TreeView from '@material-ui/lab/TreeView';
import TreeItem from '@material-ui/lab/TreeItem';
import Typography from '@material-ui/core/Typography';
import MailIcon from '@material-ui/icons/Mail';
import DeleteIcon from '@material-ui/icons/Delete';
import Label from '@material-ui/icons/Label';
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
import InfoIcon from '@material-ui/icons/Info';
import ForumIcon from '@material-ui/icons/Forum';
import LocalOfferIcon from '@material-ui/icons/LocalOffer';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import Divider from '@material-ui/core/Divider';
import HouseIcon from "@material-ui/icons/House";
import SchoolIcon from "@material-ui/icons/School";
import DesktopMacIcon from "@material-ui/icons/DesktopMac";
import {Link} from "react-router-dom";
import GavelIcon from '@material-ui/icons/Gavel';

const useTreeItemStyles = makeStyles((theme) => ({
    root: {
        color: theme.palette.text.secondary,
        '&:hover > $content': {
            backgroundColor: theme.palette.action.hover,
        },
        '&:focus > $content, &$selected > $content': {
            backgroundColor: `var(--tree-view-bg-color, ${theme.palette.grey[400]})`,
            color: 'var(--tree-view-color)',
        },
        '&:focus > $content $label, &:hover > $content $label, &$selected > $content $label': {
            backgroundColor: 'transparent',
        },
    },
    content: {
        color: theme.palette.text.secondary,
        borderTopRightRadius: theme.spacing(2),
        borderBottomRightRadius: theme.spacing(2),
        paddingRight: theme.spacing(1),
        fontWeight: theme.typography.fontWeightMedium,
        '$expanded > &': {
            fontWeight: theme.typography.fontWeightRegular,
        },
    },
    group: {
        marginLeft: 0,
        '& $content': {
            paddingLeft: theme.spacing(2),
        },
    },
    expanded: {},
    selected: {},
    link: {
        color: 'inherit',
    },
    label: {
        fontWeight: 'inherit',
        color: 'inherit',
    },
    labelRoot: {
        display: 'flex',
        alignItems: 'center',
        padding: theme.spacing(1, 0),
    },
    labelIcon: {
        marginRight: theme.spacing(1),
    },
    labelText: {
        fontWeight: 'inherit',
        flexGrow: 1,
    },
}));

function StyledTreeItem(props) {

    const classes = useTreeItemStyles();
    const {labelText, labelIcon: LabelIcon, labelInfo, color, bgColor, to, ...other} = props;
    let displayComponent;

    if (to) {
        displayComponent = (
            <Link to={to} className={classes.link}>
                <div className={classes.labelRoot}>
                    <LabelIcon color="inherit" className={classes.labelIcon}/>
                    <Typography variant="body2" className={classes.labelText}>
                        {labelText}
                    </Typography>
                </div>
            </Link>
        )
    } else {
        displayComponent = (
            <div className={classes.labelRoot}>
                <LabelIcon color="inherit" className={classes.labelIcon}/>
                <Typography variant="body2" className={classes.labelText}>
                    {labelText}
                </Typography>
            </div>
        )
    }


    return (
        <TreeItem
            label={displayComponent}
            style={{
                '--tree-view-color': color,
                '--tree-view-bg-color': bgColor,
            }}
            classes={{
                root: classes.root,
                content: classes.content,
                expanded: classes.expanded,
                selected: classes.selected,
                group: classes.group,
                label: classes.label,
            }}
            {...other}
        />
    );
}

StyledTreeItem.propTypes = {
    bgColor: PropTypes.string,
    color: PropTypes.string,
    labelIcon: PropTypes.elementType.isRequired,
    labelInfo: PropTypes.string,
    labelText: PropTypes.string.isRequired,
};

const drawerWidth = 240;
const useStyles = makeStyles((theme) => ({
    root: {
        height: 264,
        flexGrow: 1,
        maxWidth: 400,
    },
    drawerPaper: {
        position: 'relative',
        whiteSpace: 'nowrap',
        width: drawerWidth
    },
    toolbarIcon: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: '0 8px',
        ...theme.mixins.toolbar,
    },

}));


// <ListItemLink icon={<DesktopMacIcon/>} primary='Adjudication Log' to='/adjlog'/>

export default function SideBarTreeView() {
    const classes = useStyles();


    // let onNodeSelect = (event, nodeIds) => {
        // console.log('Node selected...', event, nodeIds);
    // }
    return (
        <TreeView
            multiSelect={false}
            // onNodeSelect={onNodeSelect}
            className={classes.drawerPaper}
            defaultExpanded={['1']}
            defaultSelected={['12']}
            defaultCollapseIcon={<ArrowDropDownIcon/>}
            defaultExpandIcon={<ArrowRightIcon/>}
            defaultEndIcon={<div style={{width: 24}}/>}
        >
            <div className={classes.toolbarIcon}>
            </div>
            <Divider/>
            <StyledTreeItem nodeId="1" labelText="LMS" labelIcon={SchoolIcon}>
                <StyledTreeItem nodeId="12" labelText="LMS Home" labelIcon={SchoolIcon} to='/'/>
                <StyledTreeItem nodeId="13" labelText="SafeSport LMS API" labelIcon={SchoolIcon} to='/lmsapi'/>
                <StyledTreeItem nodeId="14" labelText="LMS Infuse - Auth" labelIcon={SchoolIcon} to='/authentication'/>
                <StyledTreeItem nodeId="15" labelIcon={SchoolIcon} labelText='LMS Infuse - Profile' to='/profile'/>
                <StyledTreeItem nodeId="16" labelIcon={SchoolIcon} labelText='LMS Infuse - Catalog' to='/catalog'/>
                <StyledTreeItem nodeId="17" labelIcon={SchoolIcon} labelText='LMS Infuse - Courses' to='/courses'/>
                <StyledTreeItem nodeId="18" labelIcon={SchoolIcon} labelText='LMS Infuse - Transcript'
                                to='/transcript'/>
                <StyledTreeItem nodeId="19" labelIcon={SchoolIcon} labelText='LMS Infuse - Lesson' to='/lesson'/>
            </StyledTreeItem>
            <StyledTreeItem nodeId="2" labelText="Adjudication" labelIcon={GavelIcon}>
                <StyledTreeItem
                    nodeId="21"
                    labelText="Adjudication Log"
                    labelIcon={GavelIcon}
                    to='/adjlog'
                />
            </StyledTreeItem>
        </TreeView>
    );
}
